import React from "react";
import IroningCost from "../../assets/cost/ironing-cost.png";
function Cost() {
  return (
    <div className="w-screen h-screen">
      <div className="w-screen my-5 px-2 py-2">
        <p className="text-2xl md:text-3xl xl:text-4xl text-quaternary font-extrabold">
          • Ironing Cost
        </p>
        <br/>
        <img src={IroningCost} className="w-[80vw]" />
      </div>
    </div>
  );
}

export default Cost;
