import React from "react";
import { HomeContent } from "../../constants/content";

function WhyChooseUs() {
  return (
    <div className="flex w-screen flex-col bg-tertiary py-5 px-5 md:h-96 justify-center">
      <p className="text-center text-xl xl:text-4xl md:text-3xl md:mb-10 xl:mb-10 m text-quaternary font-extrabold ">
        {HomeContent.whyChoose.title}
      </p>
      <div
        className="grid grid-cols-2 justify-items-center gap-10 bg-tertiary py-5  xl:flex self-center"
        style={{ gridTemplateColumns: "45% 45%" }}
      >
        {HomeContent.whyChoose.options.map((item) => {
          return (
            <ReasonOptions label={item.label} id={item.id} key={item.id} />
          );
        })}
      </div>
    </div>
  );
}

export default WhyChooseUs;

function ReasonOptions({ label, id }) {
  return (
    <div id={id} className="flex w-[80%]  xl:w-fit xl:item-center">
      <span className="h-[40px] mt-1 border-l-quaternary border-l-[2px]"></span>
      <p className="text-[18px] xl:text-2xl xl:w-[70%] ml-3 items-center font-semibold text-quaternary">
        {label}
      </p>
    </div>
  );
}
