import React from "react";

function DownloadOption({ icon, url, label }) {
  function openStore() {
    window.location.href = url;
  }

  return (
    <button
      onClick={openStore}
      className="flex items-center justify-center w-full py-3 px-6 my-2 rounded-md text-white bg-[rgba(0,0,0,0.3)] transition duration-300 ease-in-out"
    >
      <img src={icon} className="w-6 h-6 mr-3" alt="icon" />
      <span className="text-lg">{label}</span>
    </button>
  );
}

export default DownloadOption;
