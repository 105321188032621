import React, { useState } from "react";
import navbarData from "../../data/navbar.json";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";

function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate(); // Add this line

  function toggleNavbar() {
    setNavbarOpen(!navbarOpen);
  }
  function handleLinkClick(link, shouldScroll = false) {
    toggleNavbar();
    navigate(link, { state: { shouldScroll } });
  }
  return (
    <div className="w-screen bg-primary flex flex-row items-center justify-between px-1 h-16">
      <div className="flex  items-center ">
        <img
          src={Logo}
          className="h-12 cursor-pointer"
          onClick={() => handleLinkClick("/")}
        />
        <p className="ml-2 text-[24px] text-white font-bold shortScreen:text-[13px]">
          Presswala Engineer
        </p>
      </div>
      <div>
        <GiHamburgerMenu
          size={30}
          color="white"
          className="cursor-pointer md:hidden"
          onClick={toggleNavbar}
        />
      </div>
      <div
        className={`fixed right-0 top-0 h-screen cursor-pointer w-2/4 transform bg-secondary p-10 transition-transform duration-700 ease-in-out md:hidden ${
          navbarOpen ? "translate-x-0" : "translate-x-full"
        } flex-col justify-center`}
      >
        <RxCross1
          onClick={toggleNavbar}
          color="white"
          size={23}
          className="absolute z-10 top-5 right-3 md:hidden"
        />
        {navbarData.map((data) => {
          return (
            <p
              key={data.id}
              onClick={() => {
                console.log(data);
                if (data.isHyperLink) {
                  window.location.href = data.isHyperLink;
                } else {
                  handleLinkClick(
                    data.link == "/scrolltoaboutcost" ? "/about" : data.link,
                    data.link == "/scrolltoaboutcost"
                  );
                }
              }}
              className={`flex cursor-pointer border-b-cyan-400 text-white transition-all duration-200 ease-in-out hover:border-b-2 hover:text-gray-700 my-10 place-self-center text-center md:text-sm xl:text-2xl ${
                data.link == location.pathname && "border-b-white border-b-2"
              }`}
            >
              {data.label}
            </p>
          );
        })}
      </div>
      <div className="hidden md:flex flex-row justify-around w-80">
        {navbarData.map((data) => {
          return (
            <p
              key={data.id}
              onClick={() => {
                if (data.isHyperLink) {
                  window.location.href = data.isHyperLink;
                } else {
                  handleLinkClick(
                    data.link == "/scrolltoaboutcost" ? "/about" : data.link,
                    data.link == "/scrolltoaboutcost"
                  );
                }
              }}
              className={`flex cursor-pointer border-b-cyan-400 text-white transition-all duration-200 ease-in-out hover:border-b-2 hover:text-lime-600 my-10 place-self-center text-center md:text-md ${
                data.link == location.pathname && "border-b-white border-b-2"
              }`}
            >
              {data.label}
            </p>
          );
        })}
      </div>
      {/* <div className="bg-white w-[90%] h-[50%] absolute top-24 left-4 rounded-md">
        <Download
          label={"Play Store"}
          url={HomeContent.playStoreUrl}
          icon={HomeContent.googleImg}
        />
        <Download
          label={"App Store"}
          url={HomeContent.appleStoreUrl}
          icon={HomeContent.appleImg}
        />
      </div> */}
    </div>
  );
}

export default Navbar;
