import React, { useEffect, useState } from "react";
import { TestimonialContent } from "../../constants/content";
import {
  Autoplay,
  Pagination,
  Navigation,
  A11y,
  Scrollbar,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Testimonial() {
  return (
    <>
      <div className=" w-screen mt-20 bg-tertiary px-5 pt-14 flex justify-center  pb-5 md:hidden">
        <Swiper
          pagination={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Scrollbar, A11y, Autoplay]}
          loop
        >
          {TestimonialContent.allTestimonial.map((item) => {
            return (
              <SwiperSlide className=" h-60 overflow-y-scroll" key={item.id}>
                <div className="flex flex-col items-center">
                  <p className="text-2xl text-quaternary font-extrabold">
                    {item.by}
                  </p>
                  <br />
                  <p className="text-[16px] text-quaternary font-semibold text-center">
                    "{item.testimonial}"
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="hidden w-screen mt-20 bg-tertiary px-5 pt-14 justify-center  pb-5 md:flex ">
        <Swiper
          autoplay={{
            delay: 5500,
            disableOnInteraction: false, 
          }}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          navigation
          slidesPerView={1}
          loop
        >
          {TestimonialContent.allTestimonial.map((item) => {
            return (
              <SwiperSlide key={item.id} className="w-screen self-center">
                <div className="w-full flex flex-col justify-center items-center">
                  <p className="text-2xl text-quaternary font-extrabold">
                    {item.by}
                  </p>
                  <br />
                  <br />
                  <p className="text-[16px] text-quaternary font-semibold text-center text-wrap w-[40%]">
                    {item.testimonial}
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default Testimonial;
