import React from "react";
import WhyChooseUs from "../../components/home/WhyChooseUs";
import OurServices from "../../components/home/OurServices";
import AboutUs from "../../components/home/AboutUs";
import Footer from "../../components/common/Footer";
import Testimonial from "../../components/home/Testimonial";
function Home() {
  return (
    <div className="w-screen bg-white">
      <AboutUs />

      <WhyChooseUs />
      <OurServices />

      <Testimonial/>

      <Footer/>
    </div>
  );
}

export default Home;
