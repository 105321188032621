import React from "react";
import { HomeContent } from "../../constants/content";

function OurServices() {
  return (
    <div className="flex flex-col w-screen pt-10">
      <p className="text-2xl text-quaternary font-semibold text-center">
        {HomeContent.ourServices.title}
      </p>

      <div className="mt-9 flex flex-col w-screen md:grid md:grid-cols-2 md:gap-2 h-full md:w-[80%] xl:w-[60%] self-center">
        {HomeContent.ourServices.services.map((item) => {
          return (
            <div
              className={`flex justify-between px-2 py-1 items-center w-[100%] my-[1px] h-32 md:my-0 md:rounded-md`}
              style={{
                backgroundColor: item.bgColor,
              }}
              key={item.id}
            >
              <p
                className="text-xl w-40 ml-4 text-white font-bold"
                style={{
                  textShadow:
                    "0.5px 0.5px 0 rgba(0,0,0,0.5), -0.5px -0.5px 0 rgba(0,0,0,0.5), 0.5px -0.5px 0 rgba(0,0,0,0.5), -0.5px 0.5px 0 rgba(0,0,0,0.3)",
                }}
              >
                {item.label}
              </p>
              <img
                alt="img"
                className="rounded-l bg-inherit h-32 w-36 px-1 py-1"
                src={item.img}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default OurServices;
