import React from "react";
import { ColorRing } from "react-loader-spinner";

function CustomButton({ onPress, label, className, loader }) {
  return (
    <button
      type="submit"
      className={
        `mt-10 bg-tertiary px-5 py-3 rounded-sm cursor-pointer hover:bg-white flex flex-row  items-center justify-center ` +
        className
      }
      onClick={onPress}
    >
      <p className="text-center text-quaternary mr-2">{label}</p>
      {loader && (
        <ColorRing
          visible={true}
          height="30"
          width="30"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"

          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      )}
    </button>
  );
}

export default CustomButton;
