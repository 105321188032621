
import "./App.css";
import MainIndex from "./screens/MainIndex";

function App() {
  return (
   
      <MainIndex />
  );
}

export default App;
