import React, { useRef, useState } from "react";
import { ContactContent } from "../../constants/content";
import Select from "react-select";
import { TextField, TextareaAutosize } from "@mui/material";
import emailjs from "@emailjs/browser";
import CustomButton from "../../components/UI/CustomButton";
import Swal from "sweetalert2";
import { emailValidator } from "../../helper/emailValidator";
function Contact() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const form = useRef();
  const [buttonLoader, setButtonLoader] = useState(false);



  

  const sendEmail = async (e) => {
    e.preventDefault();
    if (buttonLoader) return;
    setButtonLoader(true);
    try {
      const emailError = emailValidator(email);
      if (emailError) {
        Swal.fire("Invalid Email", emailError, "warning");
      } else if (name && email && subject && message) {
        await emailjs.sendForm(
          "service_dul4hoj",
          "template_yublexp",
          form.current,
          {
            publicKey: "r0q8f-8zrKXPsJrkr",
          }
        );

        Swal.fire("Email Sent", "Thank you for contacting us", "success");
        setName("");
        setNameError(false);
        setEmail("");
        setEmailError(false);
        setSubject("");
        setSubjectError(false);
        setMessage("");
        setMessageError(false);
      } else {
        Swal.fire("Incomplete Form", "Please fill all the fields", "warning");
      }
    } catch (error) {
      Swal.fire("Email Not Sent", "Something went wrong", "error");
    }
    setButtonLoader(false);
  };

  return (
    <div className="w-screen  py-4 flex flex-col">
      <br />
      <p className="text-2xl text-quaternary font-extrabold w-[80%] self-center text-center items-center">
        {ContactContent.header}
      </p>
      <br />
      <div className="bg-white p-4 md:self-center w-full">
        <ul className="list-disc ml-4 md:grid md:grid-cols-2  md:self-center md:ml-[10%]">
          {ContactContent.contactUsFor.map((item) => {
            return (
              <li
                className="text-[17px] font-semibold text-quaternary my-2"
                key={item.id}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>

      <p className="text-2xl md:mt-5 text-quaternary font-extrabold w-[80%] self-center text-center items-center">
        {"Fill The Form Below"}
      </p>
      <form
        ref={form}
        id="contact-form"
        onSubmit={sendEmail}
        className="flex flex-col self-center w-[100%] justify-center"
      >
        <br />
        <TextField
          error={nameError}
          helperText={nameError ? "Name Can't Be Empty" : ""}
          id="contact-name-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-[90%] self-center"
          label="Name"
          variant="standard"
          name="from_name"
        />
        <br />
        <TextField
          error={emailError}
          helperText={emailError ? "Email Can't Be Empty" : ""}
          id="contact-email-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-[90%] self-center"
          label="Email"
          variant="standard"
          name="from_email"
        />
        <br />
        <Select
          options={ContactContent.contactUsFor.map((item) => {
            return {
              value: item.label,
              label: item.label,
            };
          })}
          value={subject}
          styles={{
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              paddingTop: "10px",
              paddingBottom: "10px",
              marginTop: "5px",
              borderRadius: 4,
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
          }}
          className="w-[90%]  self-center"
          placeholder="Subject"
          onChange={(e) => setSubject(e)}
          name="from_subject"
        />
        <br />
        <TextareaAutosize
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-[90%] self-center border-[1px] border-gray-500 p-3"
          minRows={5}
          name="from_message"
          placeholder="Message"
        />
        <CustomButton
          loader={buttonLoader}
          className={"mx-20 w-[90%] self-center"}
          label={"Submit"}
          type="submit"
        />
      </form>
    </div>
  );
}

export default Contact;
