import React from "react";
import { FooterContent } from "../../constants/content";
import { TiSocialFacebook } from "react-icons/ti";
import { BsInstagram } from "react-icons/bs";
import Logo from "../../assets/logo.png";

function Footer() {
  return (
    <div className="w-screen bg-primary mt-20 flex flex-col px-4 py-8 md:flex-row md:justify-center">
      <div className="flex flex-col sm:items-center sm:flex-row">
        <img
          src={Logo}
          className="h-20 w-20 sm:w-30 sm:h-30 cursor-pointer mr-4"
        />
        <div className="md:flex md:flex-col w-[70%] md:w-[40%] xl:w-[450px] md:mt-10  md:mx-10">
          <p className="text-3xl text-white font-bold">
            {FooterContent.header}
          </p>
          <p className="text-[14px] mt-5 text-white font-light">
            {FooterContent.desc}
          </p>
        </div>
      </div>
      <div className="md:flex md:flex-col w-[70%] md:w-[40%] xl:w-[450px]">
        <p className="text-3xl text-white font-bold mt-10">
          {FooterContent.office.header}
        </p>
        <p className="text-[14px] mt-5 text-white font-light">
          {FooterContent.office.desc}
        </p>
        <div className="w-[20%] mt-5 h-[1px] bg-white "></div>
        <p
          href={"tel:" + FooterContent.office.mob}
          className="text-[14px]  mt-3 text-white font-light cursor-pointer"
          onClick={() =>
            (window.location.href = `tel:${FooterContent.office.mob}`)
          }
        >
          {FooterContent.office.mob}
        </p>
        <p
          href={"mailto:" + FooterContent.office.email}
          className="text-[14px]  mt-1 text-white font-light cursor-pointer"
          onClick={() =>
            (window.location.href = `mailto:${FooterContent.office.email}`)
          }
        >
          {FooterContent.office.email}
        </p>
      </div>

      <div className="md:flex md:flex-col w-[70%] md:w-[40%] xl:w-[450px]">
        <p className="text-3xl text-white font-bold mt-10 mb-2">
          {FooterContent.OperatingHoursHeader}
        </p>
        {FooterContent.OperatingHours.map((item) => {
          return (
            <p key={item.id} className="text-[14px] my-1 text-white font-light">
              {item.label}
            </p>
          );
        })}
        <br />
        <div className="flex flex-row items-center">
          <a href="https://www.facebook.com/people/Presswala-Engineer/61556516655268/">
            <TiSocialFacebook size={40} color={"white"} />
          </a>
          <a href="https://www.instagram.com/presswalaengineer?igsh=Z3lqNWx5eGVyZXkw&utm_source=qr">
            <BsInstagram size={35} color={"white"} />
          </a>
        </div>
        <div className="flex flex-col mt-4">
          <a className="text-sm text-white " href="/terms-conditions">
            Terms and Conditions
          </a>
          <a className="text-sm text-white" href="/privacy-policy">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
