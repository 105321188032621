import React from "react";
import Modal from "react-modal";
import DownloadOption from "./DownloadOption";
import { HomeContent } from "../../constants/content";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "160px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "none",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function DownloadPopUp({ isVisible, onClose }) {
  return (
    <Modal onRequestClose={onClose} isOpen={isVisible} style={customStyles} ariaHideApp={false}>
      <DownloadOption
        icon={HomeContent.googleImg}
        label={"Play Store"}
        url={HomeContent.playStoreUrl}
      />
      <DownloadOption
        icon={HomeContent.appleImg}
        label={"App Store"}
        url={HomeContent.appleStoreUrl}
      />
    </Modal>
  );
}

export default DownloadPopUp;
